

























import Vue from 'vue';
import { Component } from 'vue-property-decorator';

import UserRow from './UserRow.vue';

import { User } from '@/models';
import { UserService } from '@/lib/services';

@Component({
  components: {
    UserRow,
  },
})
export default class Users extends Vue {
  users: User[] = [];

  loading: boolean = false;

  async mounted(): Promise<void> {
    await this.refresh();
  }

  onDelete(idx: number): void {
    this.users.splice(idx, 1);
  }

  async refresh(): Promise<void> {
    this.loading = true;
    this.users = await UserService.all();
    this.loading = false;
  }
}
