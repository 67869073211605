































































































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { validate, ValidationError } from 'class-validator';

import ErrorList from '@/components/ErrorList.vue';

import { User } from '@/models';
import { UserService } from '@/lib/services';
import { unwrapError } from '@/lib/helpers';
import SearchInput from '@/components/form/SearchInput.vue';
import { supportedUserLanguages, Language } from '@/plugins/i18n';

@Component({
  components: { ErrorList, SearchInput },
})
export default class UserAddModal extends Vue {
  error: string = '';
  data: User = new User();
  errors: ValidationError[] = [];
  languages = supportedUserLanguages;

  created(): void {
    this.data.role = 'employee';
    this.data.language = Language.EN;
  }

  async onSubmit(): Promise<void> {
    this.errors = await validate(this.data);
    if (this.errors.length > 0) {
      return;
    }

    // Quick and dirty hack for password confirm
    if (this.data.password !== this.data.confirmPassword) {
      const err = new ValidationError();
      err.constraints = { confirmPassword: 'confirmation must equal password' };
      this.errors = [err];
      return;
    }

    try {
      await UserService.create(this.data);
      this.$emit('saved');
      this.close();
    } catch (e) {
      this.$toaster.error('Could not save user', unwrapError(e));
    }
  }

  close(): void {
    this.$emit('close');
  }
}
