



































import Vue from 'vue';
import { Component } from 'vue-property-decorator';

import { TermsAndConditionsService } from '@/lib/services';
import { unwrapError } from '@/lib/helpers';

@Component({})
export default class TermsAndConditions extends Vue {
  error: string = '';
  serverHasFile: boolean = false;
  selectedFile: File | null = null;
  loading: boolean = false;

  async created(): Promise<void> {
    this.serverHasFile = await TermsAndConditionsService.exists();
  }

  async save(): Promise<void> {
    if (this.selectedFile === null) return;
    this.error = '';
    this.loading = true;

    try {
      await TermsAndConditionsService.upload(this.selectedFile);
      this.serverHasFile = true;
      this.selectedFile = null;
    } catch (e) {
      this.error = unwrapError(e);
    } finally {
      this.loading = false;
    }
  }

  async deleteFile(): Promise<void> {
    this.loading = true;
    this.error = '';

    try {
      await TermsAndConditionsService.delete();
      this.serverHasFile = false;
      this.selectedFile = null;
    } catch (e) {
      this.error = unwrapError(e);
    } finally {
      this.loading = false;
    }
  }

  onSelect(e: Event): void {
    const target = e.target as HTMLInputElement;
    if (!target.files || target.files.length !== 1) {
      this.selectedFile = null;
    } else {
      this.selectedFile = target.files[0];
    }
  }

  get name(): string {
    if (!this.selectedFile) {
      return this.serverHasFile ? 'termsandconditions.pdf' : '';
    }
    return this.selectedFile.name;
  }

  get classes(): Record<string, boolean> {
    return {
      'has-name': this.name !== '',
    };
  }

  get buttonClasses(): Record<string, boolean> {
    return {
      'is-loading': this.loading,
    };
  }
}
