import { BaseService } from './base';

export interface CompanySepa {
  creditor_identifier: string;
  creditor_name: string;
  iban: string;
}

class CompanySepaService extends BaseService {
  async get(): Promise<CompanySepa> {
    const { data } = await this.axios().get(`/settings/sepa`);
    return data.data as CompanySepa;
  }

  async put(numbers: CompanySepa): Promise<void> {
    await this.axios().put(`/settings/sepa`, numbers);
  }
}

export default new CompanySepaService();
