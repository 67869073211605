



































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { AuthService } from '@/lib/services';
import { unwrapError } from '@/lib/helpers';
import { SettingState } from '@/store/modules/settings/state';
import { namespace } from 'vuex-class';
import Cancelled from '@/components/subscriptions/Cancelled.vue';
import { SubscriptionStatus } from '@/store/modules/auth/state';

const sSettings = namespace('settings');
@Component({
  components: { Cancelled },
  data() {
    return {
      SubscriptionStatus,
    };
  },
})
export default class AccountCancel extends Vue {
  private showModal: boolean = false;
  private termsAccepted: boolean = false;

  @sSettings.State('subscription')
  subscriptionState: SettingState['subscription'];

  private open() {
    this.termsAccepted = false;
    this.showModal = true;
  }

  private close() {
    this.termsAccepted = false;
    this.showModal = false;
  }

  private async cancel() {
    if (!this.termsAccepted) return;

    try {
      await AuthService.cancelAccount();
      window.location.href = '/confirm-subscription?action=cancelled';
    } catch (e) {
      this.$toaster.error('Could not cancel account', unwrapError(e));
    }
  }
}
