








































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import Popover from '@/components/Popover.vue';
import { ActionButton, ActionButtonList } from '@/components/actionbuttons';
import { PaymentMethod } from '@/models';

import { PaymentMethodService } from '@/lib/services';

@Component({
  components: {
    Popover,
    ActionButton,
    ActionButtonList,
  },
})
export default class PaymentMethodRow extends Vue {
  @Prop({ required: true })
  item: PaymentMethod;

  editItem: PaymentMethod;

  error: string = '';
  editing: boolean = false;

  mounted(): void {
    if (this.item.id < 0) {
      this.edit();
    }
  }

  edit(): void {
    this.editItem = Object.assign(new PaymentMethod(), this.item);
    this.editing = true;
  }

  async save(): Promise<void> {
    this.error = '';
    try {
      let vc;
      if (this.item.id < 0) {
        vc = await PaymentMethodService.create(this.editItem);
      } else {
        vc = await PaymentMethodService.update(this.editItem);
      }
      Object.assign(this.item, vc);
      this.editing = false;
      this.$emit('save');
    } catch (e) {
      this.error = 'An error occurred';
    }
  }

  async doDelete(): Promise<void> {
    if (this.item.id > 0) {
      await PaymentMethodService.delete(this.item.id);
    }

    this.$emit('delete');
  }

  cancel(): void {
    this.editing = false;
    if (this.item.id < 0) {
      this.$emit('delete');
    }
  }
}
