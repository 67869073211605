




























import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { EmailTextService } from '@/lib/services';
import { EmailText } from '@/models';

@Component
export default class EmailMessages extends Vue {
  emailMessages: EmailText[] = [];
  loading: boolean = false;

  async mounted(): Promise<void> {
    this.loading = true;
    this.emailMessages = await EmailTextService.all();
    this.loading = false;
  }

  edit(id: number): void {
    this.$router.push({
      name: 'settings-emailtext-edit',
      params: { messageId: `${id}` },
    });
  }

  add(): void {
    this.$router.push({
      name: 'settings-emailtext-edit',
      params: { messageId: 'new' },
    });
  }
}
