

















import Vue from 'vue';
import { Component } from 'vue-property-decorator';

import SearchInput from '@/components/form/SearchInput.vue';
import {
  DEFAULT_I18N,
  Language,
  loadLanguageAsync,
  supportedUserLanguages,
} from '@/plugins/i18n';
import { AuthService } from '@/lib/services';
import { unwrapError } from '@/lib/helpers';

@Component({
  components: {
    SearchInput,
  },
})
export default class LanguageChange extends Vue {
  languages = supportedUserLanguages;
  selectedLanguage: string | null = null;

  mounted(): void {
    this.selectedLanguage = DEFAULT_I18N.locale;
  }

  async updateLanguage(language: Language): Promise<void> {
    try {
      await AuthService.setPreferences({
        language: language,
      });
      await loadLanguageAsync(language);
    } catch (e) {
      this.$toaster.error(
        this.$tc('messages.error.update.language'),
        unwrapError(e),
      );
    }
  }
}
