




































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import Popover from '@/components/Popover.vue';
import { ActionButton, ActionButtonList } from '@/components/actionbuttons';
import { InvoiceVAT } from '@/models';
import { VatCodeService } from '@/lib/services';

@Component({
  components: {
    Popover,
    ActionButton,
    ActionButtonList,
  },
})
export default class VatCodeRow extends Vue {
  @Prop({ required: true })
  item: InvoiceVAT;

  editItem: InvoiceVAT;

  error: string = '';
  editing: boolean = false;

  mounted(): void {
    if (this.item.id < 0) {
      this.edit();
    }
  }

  edit(): void {
    this.editItem = Object.assign(new InvoiceVAT(), this.item);
    this.editing = true;
  }

  async save(): Promise<void> {
    this.error = '';
    try {
      let vc;
      if (this.item.id < 0) {
        vc = await VatCodeService.create(this.editItem);
      } else {
        vc = await VatCodeService.update(this.editItem);
      }
      Object.assign(this.item, vc);
      this.editing = false;
      this.$emit('save');
    } catch (e) {
      this.error = 'An error occurred';
    }
  }

  async doDelete(): Promise<void> {
    if (this.item.id > 0) {
      await VatCodeService.delete(this.item.id);
    }

    this.$emit('delete');
  }

  cancel(): void {
    this.editing = false;
    if (this.item.id < 0) {
      this.$emit('delete');
    }
  }

  get options(): string[] {
    return ['low', 'high', 'exempt', 'none', 'ICPS', 'ICPG', 'EBU'];
  }

  get friendlyVatType(): string {
    if (!this.item.type) {
      return '';
    }

    return this.$tc(`settings.general.vat.types.${this.item.type}`);
  }
}
