























































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import Popover from '@/components/Popover.vue';
import { ActionButton, ActionButtonList } from '@/components/actionbuttons';
import { EmailAddress } from '@/models';
import { EmailAddressService } from '@/lib/services';

@Component({
  components: {
    Popover,
    ActionButton,
    ActionButtonList,
  },
})
export default class EmailAddressRow extends Vue {
  @Prop({ required: true, type: EmailAddress })
  item: EmailAddress;

  editItem: EmailAddress;
  error: string = '';
  editing: boolean = false;

  mounted(): void {
    if (this.item.id < 0) {
      this.edit();
    }
  }

  edit(): void {
    this.editItem = Object.assign(new EmailAddress(), this.item);
    this.editing = true;
  }

  async save(): Promise<void> {
    this.error = '';
    try {
      let item: EmailAddress;
      if (this.item.id < 0) {
        item = await EmailAddressService.create(this.editItem);
      } else {
        this.error = 'Cannot edit existing emails';
        return;
      }
      Object.assign(this.item, item);
      this.editing = false;
      this.$emit('save', item);
    } catch (e) {
      this.error = 'it done derped'; // Joram where is my message
    }
  }

  async doDelete(): Promise<void> {
    if (this.item.id > 0) {
      await EmailAddressService.delete(this.item.id, this.item.type);
    }

    this.$emit('delete');
  }

  cancel(): void {
    this.editing = false;
    if (this.item.id < 0) {
      this.$emit('delete');
    }
  }

  get typeString(): string {
    switch (this.item.type) {
      case 'cc':
        return this.$tc('email.types.cc');
      case 'from':
        return this.$tc('email.types.send');
      default:
        return this.item.type;
    }
  }
}
