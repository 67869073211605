































import Vue from 'vue';
import { Component } from 'vue-property-decorator';

import Popover from '@/components/Popover.vue';
import PaymentMethodRow from './PaymentMethodRow.vue';

import { PaymentMethodService } from '@/lib/services';
import { PaymentMethod } from '@/models';

@Component({
  components: {
    Popover,
    PaymentMethodRow,
  },
})
export default class PaymentMethods extends Vue {
  paymentMethods: PaymentMethod[] = [];

  loading: boolean = false;
  newId: number = 0;

  async mounted(): Promise<void> {
    this.loading = true;
    this.paymentMethods = await PaymentMethodService.all();
    this.loading = false;
  }

  add(): void {
    this.newId -= 1;
    const vat = new PaymentMethod();
    vat.id = this.newId;
    this.paymentMethods.push(vat);
  }

  onDelete(idx: number): void {
    this.paymentMethods.splice(idx, 1);
  }
}
