























































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';

import VueQR from 'vue-qr';

import { TwoFactorService } from '@/lib/services';
import { TwoFactorConnectResponse } from '@/lib/services/twofactor';
import { unwrapError } from '@/lib/helpers';

@Component({
  components: {
    VueQR,
  },
})
export default class TwoFactorSetup extends Vue {
  connecting: boolean = false;
  status: string = 'loading';
  secrets: TwoFactorConnectResponse | null = null;
  showSecret: boolean = false;

  code: string = '';

  loadingRecoveries: boolean = false;
  recoveries: string[] = [];

  async mounted(): Promise<void> {
    this.status = (await TwoFactorService.status()).status;
  }

  async startConnect(): Promise<void> {
    this.connecting = true;
    this.secrets = await TwoFactorService.generateSecret();
  }

  async finishConnect(): Promise<void> {
    try {
      await TwoFactorService.verifyConnect(this.code);
      this.status = (await TwoFactorService.status()).status;
      this.recoveries = await TwoFactorService.generateRecoveryCodes();
      this.connecting = false;
      this.$toaster.success(this.$i18n.tc('messages.success.enable.totp'));
    } catch (e) {
      this.$toaster.error(
        this.$i18n.tc('messages.error.enable.totp'),
        unwrapError(e),
      );
    }
  }

  async remove(): Promise<void> {
    if (!confirm(this.$i18n.tc('messages.confirm.delete.totp'))) {
      return;
    }

    try {
      await TwoFactorService.remove();
      this.status = (await TwoFactorService.status()).status;
      this.connecting = false;
      this.$toaster.success(this.$i18n.tc('messages.success.disable.totp'));
    } catch (e) {
      this.$toaster.error(
        this.$i18n.tc('messages.error.disable.totp'),
        unwrapError(e),
      );
    }
  }

  async regenerate(): Promise<void> {
    try {
      this.loadingRecoveries = true;
      this.recoveries = await TwoFactorService.generateRecoveryCodes();
    } catch (e) {
      this.$toaster.error(
        this.$i18n.tc('messages.error.unknown'),
        unwrapError(e),
      );
    } finally {
      this.loadingRecoveries = false;
    }
  }

  rejectNonNumber(event: KeyboardEvent): void {
    const keyCode = event.keyCode ? event.keyCode : event.which;
    // 0xD = enter
    // 0x30 = 0
    // 0x39 = 9
    if (keyCode !== 0xd && (keyCode < 0x30 || keyCode > 0x39)) {
      event.preventDefault();
    }
  }
}
