































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';

import Popover from '@/components/Popover.vue';
import LedgerNumberRow from './LedgerNumberRow.vue';
import AccountsReceivableBalanceAccount from './AccountsReceivableBalanceAccount.vue';

import { LedgerNumber } from '@/models';
import { LedgerNumberService } from '@/lib/services';

@Component({
  components: {
    Popover,
    LedgerNumberRow,
    AccountsReceivableBalanceAccount,
  },
})
export default class LedgerNumbers extends Vue {
  salesCategories: LedgerNumber[] = [];
  arba: string = '';

  loading: boolean = false;
  newId: number = 0;

  async mounted(): Promise<void> {
    this.loading = true;
    this.salesCategories = await LedgerNumberService.all();
    this.arba = await LedgerNumberService.getArba();
    this.loading = false;
  }

  add(): void {
    this.newId -= 1;
    const ln = new LedgerNumber();
    ln.id = this.newId;
    this.salesCategories.push(ln);
  }

  onDelete(idx: number): void {
    this.salesCategories.splice(idx, 1);
  }
}
