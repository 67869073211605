


























































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import Popover from '@/components/Popover.vue';
import { ActionButton, ActionButtonList } from '@/components/actionbuttons';
import { Profile } from '@/models';
import { ProfileService } from '@/lib/services';
import { unwrapError } from '@/lib/helpers';

@Component({
  components: {
    Popover,
    ActionButton,
    ActionButtonList,
  },
})
export default class ProfileRow extends Vue {
  @Prop({ required: true, type: Profile })
  profile: Profile;
  logo: string = '';

  async created(): Promise<void> {
    if (this.profile.logo) {
      this.logo = await ProfileService.getLogo(this.profile.logo);
    }
  }

  edit(): void {
    this.$router.push({
      name: 'settings-profile-edit',
      params: { profileId: `${this.profile.id}` },
    });
  }

  async del(): Promise<void> {
    try {
      await ProfileService.delete(this.profile.id);
      this.$toaster.success(this.$tc('messages.success.delete.profile'));
      this.$emit('deleted', this.profile);
    } catch (e) {
      this.$toaster.error(
        this.$tc('messages.error.delete.profile'),
        unwrapError(e),
      );
    }
  }

  defaultize(): void {
    this.$emit('default');
  }
}
