






























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import Popover from '@/components/Popover.vue';
import UserEditModal from '@/components/modals/UserEditModal.vue';
import { ActionButton, ActionButtonList } from '@/components/actionbuttons';
import { User } from '@/models';
import { UserService } from '@/lib/services';
import { unwrapError } from '@/lib/helpers';

@Component({
  components: {
    Popover,
    ActionButton,
    ActionButtonList,
    UserEditModal,
  },
})
export default class UserRow extends Vue {
  @Prop({ required: true, type: User })
  item: User;

  showEditModal: boolean = false;

  error: string = '';

  async doDelete(): Promise<void> {
    try {
      if (this.item.id > 0) {
        await UserService.delete(this.item.id);
      }

      this.$emit('delete');
    } catch (e) {
      this.$toaster.error('Could not delete user', unwrapError(e));
    }
  }

  get friendlyRole(): string {
    return this.$tc(`settings.users.roles.${this.item.role}`);
  }
}
