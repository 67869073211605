




















































import Vue from 'vue';
import { Component } from 'vue-property-decorator';

import {
  default as CompanySepaService,
  CompanySepa as CSepa,
} from '@/lib/services/companysepa';

@Component({})
export default class CompanySepa extends Vue {
  data: CSepa = {
    creditor_identifier: '',
    creditor_name: '',
    iban: '',
  };

  async created(): Promise<void> {
    this.data = await CompanySepaService.get();
  }

  async save(): Promise<void> {
    try {
      await CompanySepaService.put(this.data);
      this.$emit('saved');
    } catch (e) {
      this.$toaster.error(this.$tc('messages.error.save.sepa'));
    }
  }
}
