




































import Vue from 'vue';
import { Component } from 'vue-property-decorator';

import {
  default as InvoiceNumbersService,
  InvoiceNumbers as INumbers,
} from '@/lib/services/invoicenumbers';

@Component({})
export default class InvoiceNumbers extends Vue {
  data: INumbers = {
    invoice: '',
    quotation: '',
  };

  async created(): Promise<void> {
    this.data = await InvoiceNumbersService.get();
  }

  async save(): Promise<void> {
    try {
      await InvoiceNumbersService.put(this.data);
      this.$emit('saved');
    } catch (e) {
      this.$toaster.error(this.$tc('messages.error.save.numbers'));
    }
  }
}
