











































import Vue from 'vue';
import { Component } from 'vue-property-decorator';

import Popover from '@/components/Popover.vue';
import VatCodeRow from './VatCodeRow.vue';

import { VatCodeService } from '@/lib/services';
import { InvoiceVAT } from '@/models';

@Component({
  components: {
    Popover,
    VatCodeRow,
  },
})
export default class VatCodes extends Vue {
  vatCodes: InvoiceVAT[] = [];

  loading: boolean = false;
  newId: number = 0;

  async mounted(): Promise<void> {
    this.loading = true;
    this.vatCodes = await VatCodeService.all();
    this.loading = false;
  }

  add(): void {
    this.newId -= 1;
    const vat = new InvoiceVAT();
    vat.id = this.newId;
    this.vatCodes.push(vat);
  }

  onDelete(idx: number): void {
    this.vatCodes.splice(idx, 1);
  }
}
