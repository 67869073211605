




































import Vue from 'vue';
import { Component } from 'vue-property-decorator';

import Popover from '@/components/Popover.vue';
import EmailAddressRow from './EmailAddressRow.vue';

import { EmailAddress } from '@/models';
import { EmailAddressService } from '@/lib/services';

@Component({
  components: {
    Popover,
    EmailAddressRow,
  },
})
export default class EmailAddresses extends Vue {
  emailAddresses: EmailAddress[] = [];

  loading: boolean = false;
  newId: number = 0;

  async mounted(): Promise<void> {
    this.loading = true;
    this.emailAddresses = await EmailAddressService.all();
    this.loading = false;
  }

  add(): void {
    this.newId -= 1;
    const email = new EmailAddress();
    email.id = this.newId;
    this.emailAddresses.push(email);
  }

  onDelete(idx: number): void {
    this.emailAddresses.splice(idx, 1);
  }
}
