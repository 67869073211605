import { render, staticRenderFns } from "./AccountCancel.vue?vue&type=template&id=0da39243&scoped=true&"
import script from "./AccountCancel.vue?vue&type=script&lang=ts&"
export * from "./AccountCancel.vue?vue&type=script&lang=ts&"
import style0 from "./AccountCancel.vue?vue&type=style&index=0&id=0da39243&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0da39243",
  null
  
)

export default component.exports