














































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import Popover from '@/components/Popover.vue';
import { ActionButton, ActionButtonList } from '@/components/actionbuttons';
import { LedgerNumberService } from '@/lib/services';
import { LedgerNumber } from '@/models';
import { unwrapError } from '@/lib/helpers';

@Component({
  components: {
    Popover,
    ActionButton,
    ActionButtonList,
  },
})
export default class LedgerNumberRow extends Vue {
  @Prop({ required: true })
  item: LedgerNumber;

  editItem: LedgerNumber;
  error: string = '';
  editing: boolean = false;

  mounted(): void {
    if (this.item.id < 0) {
      this.edit();
    }
  }

  edit(): void {
    this.editItem = Object.assign(new LedgerNumber(), this.item);
    this.editing = true;
  }

  async save(): Promise<void> {
    this.error = '';
    try {
      let ln;
      if (this.item.id < 0) {
        ln = await LedgerNumberService.create(this.editItem);
      } else {
        ln = await LedgerNumberService.update(this.editItem);
      }
      Object.assign(this.item, ln);
      this.editing = false;
      this.$emit('save', ln);
    } catch (e) {
      this.error = unwrapError(e);
    }
  }

  async doDelete(): Promise<void> {
    if (this.item.id > 0) {
      await LedgerNumberService.delete(this.item.id);
    }

    this.$emit('delete');
  }

  cancel(): void {
    this.editing = false;
    if (this.item.id < 0) {
      this.$emit('delete');
    }
  }
}
