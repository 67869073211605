






































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { ValidationError } from 'class-validator';

import ErrorList from '@/components/ErrorList.vue';

import { User, UserRole } from '@/models';
import { UserService } from '@/lib/services';
import { unwrapError } from '@/lib/helpers';

const USER_ROLES = [UserRole.OWNER, UserRole.MANAGER, UserRole.EMPLOYEE];

@Component({
  components: { ErrorList },
})
export default class UserEditModal extends Vue {
  @Prop({ required: true, type: User })
  user: User;

  errors: ValidationError[] = [];

  async onSubmit(): Promise<void> {
    // Quick and dirty hack for role checking
    if (!USER_ROLES.includes(this.user.role as UserRole)) {
      const err = new ValidationError();
      err.constraints = { role: 'Invalid role' };
      this.errors = [err];
      return;
    }

    try {
      await UserService.update(this.user);
      this.$emit('saved');
      this.close();
    } catch (e) {
      this.$toaster.error('Could not update user', unwrapError(e));
    }
  }

  close(): void {
    this.$emit('close');
  }
}
