






























































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { AuthService } from '@/lib/services';
import { unwrapError } from '@/lib/helpers';

@Component
export default class PasswordChange extends Vue {
  data = {
    current: '',
    new: '',
    confirm: '',
  };
  confirmError: string = '';
  loading: boolean = false;

  checkMatch(): void {
    this.confirmError = '';
    if (!this.data.new && !this.data.confirm) return;

    if (this.data.new.length < 8) {
      this.confirmError = 'passwords must be at least 8 characters';
    }

    if (this.data.new !== this.data.confirm) {
      this.confirmError = 'passwords do not match';
    }
  }

  async onSubmit(e: Event): Promise<void> {
    void e;
    if (this.confirmError) return;
    if (this.data.new.length < 8 || this.data.new !== this.data.confirm) return;

    this.loading = true;
    try {
      await AuthService.changePassword(this.data.current, this.data.new);
      this.$toaster.success('Password changed successfully');
      this.reset();
    } catch (e) {
      this.$toaster.error('Could not change password', unwrapError(e));
    } finally {
      this.loading = false;
    }
  }

  reset(): void {
    this.data.current = '';
    this.data.new = '';
    this.data.confirm = '';
    this.confirmError = '';
  }

  get canSubmit(): boolean {
    return (
      !!this.data.current &&
      this.data.new.length >= 8 &&
      !!this.data.confirm &&
      !this.confirmError
    );
  }
}
