



































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import Popover from '@/components/Popover.vue';
import { ActionButton, ActionButtonList } from '@/components/actionbuttons';
import { Attachment } from '@/models';
import { EmailAttachmentService } from '@/lib/services';
import { readFileBase64, unwrapError } from '@/lib/helpers';

@Component({
  components: {
    Popover,
    ActionButton,
    ActionButtonList,
  },
})
export default class EmailAttachmentRow extends Vue {
  @Prop({ required: true, type: Attachment })
  item: Attachment;

  editItem: Attachment = new Attachment();
  error: string = '';
  editing: boolean = false;
  loading: boolean = false;

  mounted(): void {
    if (this.item.id < 0) {
      this.edit();
    }
  }

  edit(): void {
    this.editItem = Object.assign(new Attachment(), this.item);
    this.editing = true;
  }

  async onSelect(e: Record<string, any>): Promise<void> {
    this.loading = true;
    if (!e.target.files) {
      this.editItem.filename = '';
      this.editItem.content = null;
      return;
    }

    const file: File = e.target.files[0];
    this.editItem.filename = file.name;
    this.editItem.content = await readFileBase64(file);

    this.loading = false;
  }

  async save(): Promise<void> {
    if (this.loading) return;
    this.error = '';
    try {
      let item: Attachment;
      if (this.item.id < 0) {
        item = await EmailAttachmentService.create(this.editItem);
      } else {
        item = await EmailAttachmentService.update(this.editItem);
      }
      Object.assign(this.item, item);
      this.editing = false;
      this.$emit('save');
    } catch (e) {
      this.error = unwrapError(e);
    }
  }

  async doDelete(): Promise<void> {
    if (this.item.id > 0) {
      await EmailAttachmentService.delete(this.item.id);
    }

    this.$emit('delete');
  }

  cancel(): void {
    this.editing = false;
    if (this.item.id < 0) {
      this.$emit('delete');
    }
  }
}
